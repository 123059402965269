export const isBase64 = (str: string): boolean => {
  try {
    return btoa(atob(str)) === str
  } catch {
    return false
  }
}

export const safeParseBase64Json = (value: string): unknown => {
  if (!value) return null
  try {
    return JSON.parse(isBase64(value) ? atob(value) : value)
  } catch {
    return null
  }
}

export const toBase64Json = (data: unknown): string => {
  if (data === undefined) {
    return btoa('null')
  }

  return btoa(JSON.stringify(data))
}